import Axios from "axios";
import { baseURL, devUrl } from "../util/api";

const url = process.env.NODE_ENV === "development" ? devUrl : baseURL;

export const instance = Axios.create({
  baseURL: `https://${url}`,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});
