import { Link } from 'gatsby'
import React from 'react'
import { ScreenContainer } from '../containers/ScreenContainer'
import * as styles from './styles.module.scss'

export const Subheader = ({ title }) => {
  return (
    <ScreenContainer>
      <div className={styles.wrapper}>
        <div className={styles.leftContainer}>
          <Link to="/" className={styles.homeLink}>
            ᐸ Forsiden
          </Link>
        </div>
        <div className={styles.rightContainer}>
          <h2>
            {title}
          </h2>
        </div>
      </div>
    </ScreenContainer>
  )
}
