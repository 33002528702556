import clsx from 'clsx';
import { Link } from 'gatsby';
import React, { useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { ScreenContainer } from '../components/common/containers/ScreenContainer';
import { Subheader } from '../components/common/Subheader';
import { Layout } from '../components/Layout';
import { ArticlesList } from './common/ArticlesList';
import * as styles from './styles.module.scss';
import thumb from '../assets/icons/thumb.svg';
import { sendFeedback } from '../api/guide';
import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/index.scss';

const Guide = ({ pageContext }) => {
  const allArticles = pageContext.articles;
  const group = pageContext.group;
  const [groupId, setGroupId] = useState(group?.id || 1);
  const { activeGroup, nextGroup } = useMemo(() => allArticles.reduce((acc, group, index) => {
    if (group.id === groupId) {
      acc.activeGroup = group;
      acc.nextGroup = allArticles[index + 1] || null;
    }
    return acc;
  }, {}), [groupId, allArticles]);

  const pageTitle = useMemo(() => activeGroup?.guideDocs?.find(gu => gu.uri === group?.uri)?.guideTitle,
    [activeGroup, group]
  );

  const [showSnack, setShowSnack] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    const posY = document.getElementById(pageContext?.group?.uri)?.getBoundingClientRect()?.top;
    window.scrollTo(0, posY - 90);
  }, [pageContext]);

  const onFeedbackClick = (positive) => {
    sendFeedback(group?.uri || '/', positive)
      .then(({ status }) => {
        if (status === 200) setShowSnack('Success! Thanks for your feedback.')
      })
      .catch(() => setShowSnack('Whoops! Something went wrong.'));
  };

  return (
    <Layout noSearch title={pageTitle || "Hjælp til holdingselskaber"}>
      <div style={{ display: 'flex', marginTop: 84, flexDirection: 'column' }}>
        <Subheader title={activeGroup?.groupTitle} />
        <ScreenContainer>
          <div className={styles.tabsWrapper}>
            <ArticlesList allArticles={allArticles} currentGroupId={groupId} onClick={setGroupId} />
            <div className={styles.content}>
              {activeGroup?.guideDocs.map(guide => (
                <div
                  key={guide.uri}
                  className={clsx(styles.articleDiv, {
                    [styles.active]: group?.uri === guide.uri
                  })}
                >
                  <Link
                    id={guide.uri}
                    key={guide.uri}
                    to={`/da/guide/${guide.uri}`}
                    className={styles.articleName}
                  >
                    <h3>{guide.guideTitle}</h3>
                  </Link>
                  <ReactMarkdown >{guide.contents}</ReactMarkdown>
                </div>
              ))}
              {nextGroup &&
                <div className={styles.readNext}>
                  <h5>LÆS NÆSTE</h5>
                  <Link
                    to={`/da/guide/${nextGroup?.guideDocs[0]?.uri}`}
                  >
                    {nextGroup?.groupTitle}
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.5 4L11.09 5.41L16.67 11H4.5V13H16.67L11.09 18.59L12.5 20L20.5 12L12.5 4Z" fill="#0071EB" />
                    </svg>
                  </Link>
                </div>
              }
              <div className={styles.rate}>
                <h5>Var denne artikel til hjælp?</h5>
                <div className={styles.thumbButtons}>
                  <button
                    type="button"
                    disabled={showSnack?.length}
                    onClick={() => onFeedbackClick(true)}
                  >
                    <img src={thumb} alt="like" />
                  </button>
                  <button
                    type="button"
                    disabled={showSnack?.length}
                    onClick={() => onFeedbackClick(false)}
                  >
                    <img src={thumb} className={styles.rotate} alt="dislike" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ScreenContainer>
      </div>
      {showSnack?.length &&
        <Snackbar
          message={showSnack}
          actionText="✕"
          className={styles.snackbarStyles}
          timeoutMs={4000}
          onClose={() => setShowSnack(null)}
        />
      }
    </Layout>
  )
}

export default Guide;