import clsx from 'clsx'
import React, { useState } from 'react'
import * as styles from './styles.module.scss'
import liIcon from "../../../assets/icons/article.svg";

export const ArticlesList = ({ allArticles, currentGroupId, onClick }) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  }

  return (
    <div className={styles.articler}>

      {!show &&
        <button onClick={toggleShow} className={styles.toggleButton}>
          {/* Note: can be moved to assets */}
          <svg style={{ margin: '0 12px' }} width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="8" width="16" height="2" fill="#0071EB" />
            <rect width="16" height="2" fill="#0071EB" />
          </svg>
          Gennemse artikler
        </button>}

      <h4>Artikler</h4>

      <div className={clsx(styles.overlay, { [styles.activeOverlay]: show })}>
        <button onClick={toggleShow} className={styles.toggleButton}>
          <svg style={{ margin: '0 12px' }} width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="2" fill="#0071EB" />
          </svg>
          Gennemse artikler
        </button>
        <ul className={styles.tabs}>
          {allArticles.map(articleGroup => (
            <li
              className={clsx({ [styles.active]: articleGroup.id === currentGroupId })}
              key={articleGroup.groupTitle}
            >
              {/* Note: better to remove padding / make button take all li space */}
              <img src={liIcon} alt="ico" height={24} />
              <button
                onClick={() => {
                  onClick(articleGroup.id);
                  setShow(false);
                }}
              >
                {articleGroup.groupTitle}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
