import { instance } from "../axios";
export const GUIDE_PAGE_CONTENT_URL = "/open/guidedocs";
export const GUIDE_PAGE_DOC_CONTENT_URL = "/open/guidedoc";
export const FEEDBACK = "/open/mitholding/feedback";

export function fetchGuidePageContent() {
  return instance.post(GUIDE_PAGE_CONTENT_URL);
}

export function fetchGuidePageDocContent(uri) {
  return instance.post(GUIDE_PAGE_DOC_CONTENT_URL, { data: { uri } });
}

export async function sendFeedback(location, positive) {
  return instance.post(FEEDBACK, {
    location,
    positive,
  });
  // const response = await fetch(
  //   "https://mrtest.multiregnskab.dk/open/mitholding/feedback",
  //   {
  //     method: "POST",
  //     mode: "no-cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //     body: JSON.stringify({ location, positive }),
  //   }
  // );
  // return await response.json();
}
