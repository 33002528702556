// extracted by mini-css-extract-plugin
export const tabsWrapper = "styles-module--tabsWrapper--2DGi6";
export const content = "styles-module--content--3UXj2";
export const articleDiv = "styles-module--articleDiv--36oj0";
export const active = "styles-module--active--2vMO8";
export const highlightP = "styles-module--highlightP--1Zn5H";
export const articleName = "styles-module--articleName--A29T2";
export const readNext = "styles-module--readNext--1TBUF";
export const rate = "styles-module--rate--2yqYT";
export const thumbButtons = "styles-module--thumbButtons--1Hinr";
export const rotate = "styles-module--rotate--16Cb9";
export const snackbarStyles = "styles-module--snackbarStyles--21X0n";